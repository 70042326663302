@import "../../../node_modules/swiper/swiper.min.css";
@import "../../../node_modules/swiper/modules/pagination/pagination.min.css";
@import "../../../node_modules/swiper/modules/navigation/navigation.min.css";
@import "../../../node_modules/swiper/modules/autoplay/autoplay.min.css";
body,
html {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}
.page-home nav.nav-wrap {
  height: 85px;
  background: #fff;
  width: 100vw;
  z-index: 970;
  box-shadow: 0 5px 8px #000 26;
  position: relative;
  top: 0;
  display: flex;
  justify-content: center;
}
.page-home nav.nav-wrap .nav {
  margin: 0 auto;
  width: 1140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 1140px) {
  .page-home nav.nav-wrap .nav {
    width: 1140px;
  }
}
@media screen and (max-width: 1140px) {
  .page-home nav.nav-wrap .nav {
    width: 100vw;
    padding: 0 30px;
  }
}
.page-home nav.nav-wrap .nav .logo {
  margin: 15px 0;
}
.page-home nav.nav-wrap .nav .menu {
  background: transparent;
  color: #262220;
  line-height: 70px;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.page-home nav.nav-wrap .nav .menu .item {
  float: left;
  text-align: center;
  width: 120px;
  font-size: 14px;
  color: #262220;
  border: none;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .page-home nav.nav-wrap .nav .menu .item {
    font-size: 12px;
    width: 60px;
  }
}
.page-home > .content {
  width: 100vw;
  height: 980px;
  position: relative;
  text-align: center;
  border-color: #666;
  background-color: #f6f3f1;
  background-repeat: no-repeat;
  background-image: url("../../images/decorates/decorate-1.png"), url("../../images/decorates/decorate-2.png"),
    url("../../images/decorates/decorate-3.png"), url("../../images/decorates/decorate-4.png");
  background-size:
    412px 318px,
    614px 470px,
    600px 470px,
    215px 348px;
  background-position:
    top -80px left 186px,
    left 0 bottom 0,
    right 0 bottom 0,
    right 0 bottom 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.page-home > .content:before {
  background-size: cover;
  background-position: 50%;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 771px;
  content: "";
  z-index: -1;
}
.page-home > .content .logo {
  height: 150px;
  width: 1140px;
  background-position: 125px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
}
@media screen and (min-width: 1140px) {
  .page-home > .content .logo {
    width: 1140px;
  }
}
@media screen and (max-width: 1140px) {
  .page-home > .content .logo {
    width: 100vw;
  }
}
@media screen and (max-width: 800px) {
  .page-home > .content .logo {
    flex-direction: column;
    height: auto;
  }
}
@media screen and (max-width: 800px) {
  .page-home > .content .logo > * {
    margin: 10px 0;
  }
}
.page-home > .content .logo .image {
  background: url("../../images/logo.png") no-repeat center;
  background-size: contain;
  height: 150px;
  width: 150px;
}
@media screen and (min-width: 800px) {
  .page-home > .content .logo .image {
    margin-left: 20px;
  }
}
@media screen and (max-width: 800px) {
  .page-home > .content .logo .image {
    height: 120px;
    width: 120px;
  }
}
.page-home > .content .logo .slogan {
  flex-grow: 1;
  color: #fff;
  font-style: italic;
  font-size: 18px;
  white-space: nowrap;
}
@media screen and (max-width: 1140px) {
  .page-home > .content .logo .slogan {
    font-size: 16px;
  }
}
@media screen and (max-width: 900px) {
  .page-home > .content .logo .slogan {
    font-size: 12px;
  }
}
@media screen and (max-width: 800px) {
  .page-home > .content .logo .slogan {
    flex-grow: 0;
  }
}
.page-home > .content .logo .download {
  color: #fff;
  background-color: #84542e;
  border-radius: 10px;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .page-home > .content .logo .download {
    height: 40px;
    width: 120px;
  }
}
.page-home > .content .app {
  flex-grow: 1;
  width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1140px) {
  .page-home > .content .app {
    width: 100vw;
  }
}
.page-home > .content .app .app-info {
  display: flex;
  flex-direction: column;
}
.page-home > .content .app .app-info .app-introduce {
  width: 532px;
  height: 225px;
}
.page-home > .content .app .app-info .scan {
  width: 370px;
  height: 145px;
  margin-top: 75px;
  background-image: url("../../images/border.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.page-home > .content .app .app-info .scan .qrcode {
  height: 111px;
  width: 111px;
  background: url("https://acupoint-attach.oss-cn-shenzhen.aliyuncs.com/images/portal/download-qrcode.png") center
    no-repeat;
  background-size: contain;
}
@media screen and (max-width: 800px) {
  .page-home > .content .app .app-info .scan .qrcode {
    display: none;
  }
}
.page-home > .content .app .app-info .scan .scan-tip {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-home > .content .app .app-info .scan .scan-tip img {
  width: 29px;
  height: 29px;
}
.page-home > .content .app .app-info .scan .scan-tip h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 2px 0;
  color: #262220;
}
.page-home > .content .app .app-info .scan .scan-tip span {
  font-size: 15px;
  color: #6b6461;
}
.page-home > .content .app .app-info .downloads {
  display: flex;
}
.page-home > .content .app .app-info .downloads .download-button {
  width: 150px;
  height: 50px;
  color: #262220;
  background-image: url("../../images/button_back.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-right: 20px;
}
.page-home > .content .app .app-info .downloads .download-button .anticon {
  font-size: 20px;
}
.page-home > .content .app .app-info .downloads .download-button .button-text {
  font-size: 14px;
}
.page-home > .content .app .app-video {
  width: 300px;
  height: 670px;
  border: solid 3px #262220;
  border-radius: 32px;
  margin-left: 160px;
  overflow: hidden;
}
.page-home .sub-content {
  min-height: 760px;
}
.page-home .introduce {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-home .introduce .introduce-no {
  width: 88px;
  height: 66px;
  background-size: cover;
  margin-bottom: -10px;
}
.page-home .introduce .no1 {
  background-image: url("../../images/introduces/no-01.png");
}
.page-home .introduce .no2 {
  background-image: url("../../images/introduces/no-02.png");
}
.page-home .introduce .no3 {
  background-image: url("../../images/introduces/no-03.png");
}
.page-home .introduce .no4 {
  background-image: url("../../images/introduces/no-04.png");
}
.page-home .introduce h1 {
  color: #262220;
  font-size: 42px;
  font-weight: bold;
  margin: 0;
}
.page-home .introduce .introduce-text {
  display: flex;
  flex-direction: column;
}
.page-home .introduce .introduce-text p {
  margin: 0;
  font-size: 17px;
  color: #262220;
  line-height: 34px;
}
.page-home .introduce .quxue-video {
  position: relative;
  width: 650px;
  height: 500px;
}
.page-home .introduce .quxue-video > img,
.page-home .introduce .quxue-video > div {
  border: 3px white solid;
  border-radius: 4px;
  overflow: hidden;
}
.page-home .introduce .quxue-video > img {
  width: 420px;
  height: 270px;
  position: absolute;
  top: 70px;
  left: 15px;
}
.page-home .introduce .quxue-video > div {
  position: absolute;
  top: 200px;
  left: 90px;
}
.page-home .introduce-two,
.page-home .introduce-four {
  background-color: #f6f3f1;
  flex-direction: row-reverse;
}
.page-home .introduce-two .introduce-text,
.page-home .introduce-four .introduce-text {
  align-items: end;
}
.page-home .introduce-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-home .introduce-title span {
  font-size: 42px;
  font-weight: bold;
  margin: 0 24px;
}
.page-home .more-fun,
.page-home .medicines {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page-home .more-fun .more-fun-list {
  display: flex;
  width: 100%;
  max-width: 1100px;
  justify-content: space-around;
}
.page-home .more-fun .more-fun-list .more-fun-item > p {
  text-align: center;
  margin-top: 40px;
  font-size: 15px;
  color: #262220;
}
.page-home .medicines {
  background-color: #f6f3f1;
}
.page-home .to-medicines {
  border: #dcd1bf 1px solid;
  padding: 7px 18px;
  color: #bca98a;
  font-size: 18px;
  margin-top: 20px;
}
.page-home .footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 187px;
  background-color: #392f2a;
  color: #b8b8b8;
}
.page-home .footer > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1920px;
}
.page-home .footer > div .contact {
  display: flex;
  flex-direction: column;
}
.page-home .footer > div .contact span {
  margin: 7px 0;
}
.page-home .footer > div .scan {
  display: flex;
}
.page-home .footer > div .scan .qrcode {
  height: 110px;
  width: 110px;
  background: url("https://acupoint-attach.oss-cn-shenzhen.aliyuncs.com/images/portal/download-qrcode.png") center
    no-repeat;
  background-size: contain;
}
@media screen and (max-width: 800px) {
  .page-home .footer > div .scan .qrcode {
    display: none;
  }
}
.page-home .footer > div .scan .downloads {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
}
.page-home .footer > p {
  text-align: center;
}
.page-home .footer > p a {
  color: #b8b8b8;
}
@media screen and (max-width: 800px) {
  .page-home .footer {
    height: 120px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
