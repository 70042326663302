.medicines > .list {
  width: 100%;
  height: 883px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.medicines .chengyao {
  background-color: white;
}
.medicines .chengyao > div,
.medicines .xiyao > div {
  display: flex;
  flex-wrap: wrap;
}
.medicines .chengyao > div .medicine-item > div,
.medicines .xiyao > div .medicine-item > div {
  width: 260px;
  height: 260px;
}
