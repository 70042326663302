.book-list {
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1100px;
}

.book-list + .introduce-title {
    margin-top: 60px;
}

.book-item {
    width: 200px;
    margin: 20px 10px;
    padding: 5px;
}

.book-item p {
    margin-top: 10px;
    color: #262220;
    font-size: 15px;
}

.book-item > .book-info {
    padding: 5px;
}

.book-item > .book-img {
    position: relative;
    margin-bottom: 20px;
    width: 180px;
    height: 280px;
}

.book-item > .book-img > img {
    width: 100%;
    height: 100%;
    border: 2px transparent solid;
    padding: 5px;
}

.book-item > .book-img .book-detail {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    display: flex;
    justify-content: center;
}

.book-item > .book-img .book-detail span {
    padding: 4px 16px;
    background-color: #c1a16b;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    display: none;
}

.book-item:hover {
    cursor: pointer;
}

.book-item:hover img {
    border: 2px #c1a16b solid;
}

.book-item:hover p, .book-item:hover h3 {
    color: #c1a16b;
}

.book-item:hover a {
    display: block !important;
}
