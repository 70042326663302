.app-view.clock-invite {
    /*background: url("../../images/clock-invite/bg.jpg") no-repeat 0px 0px;*/
    /*background-size: 100% 100%;*/
    width: 100vw;
    /*height: 100vh;*/
    position: relative;
}

.clock-invite > img {
    width: 100%;
    height: auto;
}

/* .app-view.clock-invite .bg-top-left {
    background: url("../../images/invite_code/bg-top-left.png") no-repeat 0px 0px;
    background-size: cover;
    width: 61px;
    height: 105px;
    top: 75px;
    left: 0;
    position: absolute;
}
.app-view.clock-invite .bg-top-right {
    background: url("../../images/invite_code/bg-top-right.png") no-repeat 0px 0px;
    background-size: cover;
    width: 186px;
    height: 167px;
    position: absolute;
    top: 0;
    right: 0;
}
.app-view.clock-invite .logo-banner {
    background: url("../../images/invite_code/logo-banner.png") no-repeat center;
    background-size: contain;
    width: 100vw;
    height: 64px;
    position: absolute;
    left: 0;
    right: 0;
    top: 39px;
} */
/* .app-view.clock-invite .title-banner {
    background: url("../../images/invite_code/title-banner.png") no-repeat center;
    background-size: contain;
    width: 100vw;
    height: 37px;
    position: absolute;
    left: 0;
    right: 0;
    top: 120px;
}
.app-view.clock-invite .banners {
    position: absolute;
    top: 170px;
    width: 100vw;
}
.app-view.clock-invite .banners div {
    height: 108px;
    margin-top: 10px;
}
.app-view.clock-invite .banners .no1 {
    background: url("../../images/invite_code/banner-1.png") no-repeat center;
    background-size: contain;
}
.app-view.clock-invite .banners .no2 {
    background: url("../../images/invite_code/banner-2.png") no-repeat center;
    background-size: contain;
}
.app-view.clock-invite .banners .no3 {
    background: url("../../images/invite_code/banner-3.png") no-repeat center;
    background-size: contain;
}
.app-view.clock-invite .banners .no4 {
    background: url("../../images/invite_code/banner-4.png") no-repeat center;
    background-size: contain;
} */
.app-view.clock-invite .btn.open-app {
    position: fixed;
    bottom: 20px;
    height: 63px;
    width: 274px;
    background: url("../../images/clock-invite/btn_app.png") no-repeat center;
    background-size: contain;
    display: block;
    border: none;
}

.app-view.clock-invite #wechat-launch-btn {
    position: fixed;
    bottom: 20px;
    height: 63px;
    width: 274px;
    background: url("../../images/clock-invite/btn_app.png") no-repeat center;
    background-size: contain;
    display: block;
    border: none;
    outline: none;
}

.mask {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
}

.mask > div {
    display: flex;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    padding: 10px 20px;
}

.mask > div > h4 {
    width: 100%;
    text-align: center;
    font-weight: bold;
    /*font-size: 20px;*/
}

.mask > div > img {
    width: 40px;
}