.simple-video {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.simple-video > video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: fill;
}
.simple-video > img {
  z-index: 1;
  transition: opacity 0.2s;
}
.simple-video .pause-button {
  opacity: 0;
}
.simple-video:hover .pause-button {
  opacity: 1;
}
