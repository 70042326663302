.medicine-detail-page {
  background-color: #f6f3f1;
  padding: 14px 0 60px;
}
.medicine-detail-page .medicine-detail {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}
.medicine-detail-page .medicine-detail .breadcrumb {
  display: flex;
  font-size: 12px;
  color: #262220;
  margin: 14px 0;
}
.medicine-detail-page .medicine-detail .breadcrumb a {
  color: #262220;
}
.medicine-detail-page .medicine-detail .content {
  background-color: white;
  padding: 45px 80px;
  color: #262220;
  position: relative;
}
.medicine-detail-page .medicine-detail .content .main-description {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.medicine-detail-page .medicine-detail .content .main-description h1 {
  font-size: 28px;
  font-weight: bold;
}
.medicine-detail-page .medicine-detail .content .main-description p {
  font-size: 15px;
}
.medicine-detail-page .medicine-detail .content .main-description > img {
  width: 280px;
  height: 230px;
}
.medicine-detail-page .medicine-detail .content .sub-description {
  font-size: 15px;
}
.medicine-detail-page .medicine-detail .content .sub-description .description-item {
  line-height: 2;
}
.medicine-detail-page .medicine-detail .locked-content {
  height: 600px;
  overflow: hidden;
}
.medicine-detail-page .medicine-detail .hide-box {
  width: 100%;
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0) 0%, #fff 30%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 45px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.medicine-detail-page .medicine-detail .hide-box .scan {
  cursor: pointer;
  background-color: #eeeff2;
  border-radius: 5px;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  margin: 8px 0 1px;
}
.medicine-detail-page .medicine-detail .hide-box .scan span {
  font-size: 12px;
  margin-left: 8px;
}
.medicine-detail-page .medicine-detail .hide-box img {
  width: 110px;
  height: 110px;
}
.medicine-detail-page .medicine-detail .hide-box .pay {
  font-size: 17px;
  font-weight: bold;
}
.medicine-detail-page .medicine-detail .hide-box .pay span {
  color: #ff4c12;
  font-size: 30px;
}
.medicine-detail-page .medicine-detail .hide-box .login-button {
  background-color: #c1a16b;
  border-color: #c1a16b;
  padding: 0 20px;
  width: 150px;
  height: 42px;
  border-radius: 4px;
}
.medicine-detail-page .medicine-detail .hide-box-locked {
  display: none;
}
.login-modal {
  width: 520px;
  height: 420px;
  background-image: url('../../../images/login-modal-background.png');
  background-size: 520px 420px;
  padding: 0;
  overflow: hidden;
}
.login-modal .ant-modal-body {
  padding: 50px 70px;
}
.login-modal .ant-modal-content {
  background-color: transparent;
}
.login-modal .ant-form {
  width: 300px;
  display: block;
  margin: auto;
}
.login-modal .ant-form .logo {
  margin: 0 auto 40px;
  display: block;
}
.login-modal .ant-form input {
  height: 50px;
  margin: auto;
  display: block;
}
.login-modal .ant-form button {
  width: 300px;
  height: 50px;
  background-color: #c1a16b;
  border-color: #c1a16b;
  margin: 10px auto 0;
  display: block;
}
.login-modal .ant-form .login-fail-message {
  width: 300px;
  height: 20px;
  margin: 5px auto 0;
  font-size: 12px;
  color: #ff0800;
}
.login-modal .ant-form .login-fail-message .anticon {
  margin-right: 2px;
}
