.app-view.invite-code {
    background: url("../../images/invite_code/bg.png") no-repeat 0px 0px;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
}
.app-view.invite-code .bg-top-left {
    background: url("../../images/invite_code/bg-top-left.png") no-repeat 0px 0px;
    background-size: cover;
    width: 61px;
    height: 105px;
    top: 75px;
    left: 0;
    position: absolute;
}
.app-view.invite-code .bg-top-right {
    background: url("../../images/invite_code/bg-top-right.png") no-repeat 0px 0px;
    background-size: cover;
    width: 186px;
    height: 167px;
    position: absolute;
    top: 0;
    right: 0;
}
.app-view.invite-code .logo-banner {
    background: url("../../images/invite_code/logo-banner.png") no-repeat center;
    background-size: contain;
    width: 100vw;
    height: 64px;
    position: absolute;
    left: 0;
    right: 0;
    top: 39px;
}
.app-view.invite-code .title-banner {
    background: url("../../images/invite_code/title-banner.png") no-repeat center;
    background-size: contain;
    width: 100vw;
    height: 37px;
    position: absolute;
    left: 0;
    right: 0;
    top: 120px;
}
.app-view.invite-code .banners {
    position: absolute;
    top: 170px;
    width: 100vw;
}
.app-view.invite-code .banners div {
    height: 108px;
    margin-top: 10px;
}
.app-view.invite-code .banners .no1 {
    background: url("../../images/invite_code/banner-1.png") no-repeat center;
    background-size: contain;
}
.app-view.invite-code .banners .no2 {
    background: url("../../images/invite_code/banner-2.png") no-repeat center;
    background-size: contain;
}
.app-view.invite-code .banners .no3 {
    background: url("../../images/invite_code/banner-3.png") no-repeat center;
    background-size: contain;
}
.app-view.invite-code .banners .no4 {
    background: url("../../images/invite_code/banner-4.png") no-repeat center;
    background-size: contain;
}
.app-view.invite-code .btn.open-app {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 93px;
    background: url("../../images/invite_code/button-open-app.png") no-repeat center;
    background-size: contain;
    display: block;
    border: none;
}
.app-view.invite-code #wechat-launch-btn {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 93px;
    background: url("../../images/invite_code/button-open-app.png") no-repeat center;
    background-size: contain;
    display: block;
    border: none;
    outline: none;
}
