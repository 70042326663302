@import "~antd/dist/antd.css";

form.feedback-form {
    padding: 30px;
}

.text-item {
    /*line-height: 50 rpx;*/
    margin-top: 18px;
    text-indent: 2em;
    font-size: 15px;
}

.highlight {
    font-weight: bold;
    text-decoration: underline;
}