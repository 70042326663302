@font-face {
  font-family: 'custom';
  src: url("../../fonts/custom.ttf");
}
.feedback {
  max-width: 500px;
  margin: 0.5em auto 0 auto;
  font-family: 'custom';
}
.feedback * {
  box-shadow: none !important;
}
.feedback > div {
  margin: 0 15px;
}
.feedback .tip {
  color: #6b6461;
  font-size: 12px;
}
.feedback-btn {
  width: 100%;
}
.feedback .ant-radio-group > * {
  margin: 4px !important;
  border-radius: 4px;
}
.feedback .ant-radio-group .ant-radio-button-wrapper, .feedback .ant-radio-group .ant-radio-button-wrapper-checked, .feedback .ant-radio-group .ant-radio-button-wrapper:hover {
  background: #f3f0ec;
  margin: 0 4px;
  padding: 0 20px;
  border: none;
}
.feedback .ant-radio-group .ant-radio-button-wrapper-checked, .feedback .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #b39158 !important;
}
.feedback .ant-radio-group .ant-radio-button-wrapper:hover {
  color: #b39158;
}
.feedback .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before, .feedback .ant-radio-group .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child, .feedback .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: rgba(255, 255, 255, 0);
}
.feedback .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
}
.feedback .ant-input {
  background: #f3f0ec;
}
.feedback .ant-input:hover {
  border-color: #b39158;
}
.feedback .ant-input:focus {
  border-color: #b39158;
  box-shadow: 0 0 0 2px rgba(179, 145, 88, 0.2);
}
.feedback .ant-form-item {
  margin-bottom: 12px;
}
.feedback .feedback-btn {
  color: #3b3b3b;
  background: linear-gradient(90deg, #e2cfa4, #b39158) no-repeat;
  border: none;
}
.feedback .feedback-btn:after {
  box-shadow: 0 0 0 2px rgba(179, 145, 88, 0.2) !important;
}
