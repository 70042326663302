.medicine-item p {
  margin-top: 10px;
  color: #262220;
  font-size: 15px;
  text-align: center;
}
.medicine-item > div {
  position: relative;
  margin: 20px 20px 10px;
  background-size: cover;
  width: 160px;
  height: 160px;
  background-image: url("../../images/medicines/medicine-border.png");
  overflow: hidden;
  padding: 20px;
}
.medicine-item > div > img {
  width: 100%;
  height: 100%;
}
.medicine-item > div .medicine-detail {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
}
.medicine-item > div .medicine-detail a {
  padding: 4px 16px;
  background-color: #c1a16b;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  display: none;
}
.medicine-item:hover > div {
  background-image: url("../../images/medicines/medicine-border-checked.png");
}
.medicine-item:hover p {
  color: #c1a16b;
}
.medicine-item:hover a {
  display: block !important;
}
